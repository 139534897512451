<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>{{$t('tiktok.viewsAndLikes')}}</b-card-title>
        </b-card-header>
        <b-card-body class="pb-0">
          <component :is="apex_charts" type="line" height="400" :options="chartOptions" :series="series"></component>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {BCol, BRow, BCardHeader, BCardBody, BCard, BCardTitle} from 'bootstrap-vue'
import { getFormat } from '@/libs/utils/formats';
import { getSeries, getCategories } from '@/libs/utils/series_graphs';

export default {
  components: {
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BCard,
    BCardTitle,
  },
  props: {
    graph: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      apex_charts: null,
    }
  },
  computed: {
    chartOptions() {
      const chart = {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#E75480', '#67dab9'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#E75480', '#67dab9'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          title: {
            text: 'Video Ids',
            offsetY: -10,
            style: {
              fontWeight: 400,
              fontSize: '1rem',
              color: '#b9b9c3'
            }
          },
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              const id = `${val}0`
              return `${id.substr(0,5)}...`
            },
          },
          axisTicks: {
            show: false,
          },
          categories: [],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              return getFormat(val)
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      }
      chart.xaxis.categories = getCategories(this.graph, 'id')
      return chart
    },
    series() {
      const serie = [{name: 'likes', data: []}, { name: `${this.$t('youtube.views')}`, data: []}]
      serie[0].data = getSeries(this.graph, 'y')
      serie[1].data = getSeries(this.graph, 'x')
      return serie
    }
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
}
</script>
